import '../scss/design.scss';

let nameClass       = 'hidden',
   h              = document.querySelector(".sticky"),
   stuck = false,
   stickPoint     = getDistance(),
   menu           = document.querySelector('.js_menu'),
   menuContent    = document.querySelector(".nav_mobile_content"),
   navOpen        = document.querySelector(".nav_mobile"),
   btnClose       = document.querySelector(".close");

menu.addEventListener('click', function(e) {
  e.preventDefault();
  console.log('Oki');
  menuContent.classList.toggle(nameClass);
  navOpen.classList.toggle('is_open');
});

btnClose.addEventListener('click', function() {
  menuContent.classList.add(nameClass);
  navOpen.classList.remove('is_open');
});

function getDistance() {
  let topDist = h.offsetTop;
  return topDist;
}

window.onscroll = function(e) {
  let distance = getDistance() - window.scrollY,
      offset = window.scrollY;
  if ( (distance <= 0) && !stuck) {
    h.classList.add('fixed');
    stuck = true;
  } else if (stuck && (offset <= stickPoint)){
    h.classList.remove('fixed');
    stuck = false;
  }
}
